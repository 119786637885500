import React from 'react'
import PropTypes from 'prop-types'
import { useTheme } from 'emotion-theming'

const Logo = ({ color }) => {
  const { colors } = useTheme()

  const colorMap = {
    primary: colors.primary,
    inverse: colors.lightest
  }

  return (
    <svg
      width="100%"
      viewBox="0 0 44 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34.4555 25C31.7155 23.0446 27.8657 21.6519 22.8187 20.6873C14.8067 19.0745 12.5891 16.9707 12.5891 14.1657C12.5891 10.5193 16.3805 8.34538 21.388 8.34538C27.9692 8.34538 33.4059 11.6412 35.1942 13.1139C35.6234 13.4645 35.9811 13.3944 36.2673 12.9736L39.844 6.87277C40.1302 6.45203 39.9871 6.10141 39.701 5.89104C38.1987 4.41842 31.6175 0.0707162 22.1033 0.000591645C10.5862 -0.0695349 3.79033 6.10142 4.00494 14.7267C4.16754 19.389 5.99329 22.6247 9.56095 25L34.4555 25Z"
        fill={colorMap[color]}
      />
      <path
        d="M0 54L44 54L44 25H34.4391C38.0067 27.3753 39.8325 30.611 39.9951 35.2733C40.2097 43.8986 33.4138 50.0695 21.8967 49.9994C12.3825 49.9293 5.80128 45.5816 4.29904 44.109C4.0129 43.8986 3.86983 43.548 4.15597 43.1272L7.73273 37.0264C8.01887 36.6056 8.37655 36.5355 8.80577 36.8861C10.5941 38.3588 16.0308 41.6546 22.612 41.6546C27.6195 41.6546 31.4109 39.4807 31.4109 35.8343C31.4109 33.0293 29.1933 30.9255 21.1813 29.3127C16.1343 28.3481 12.2846 26.9554 9.54448 25H2.53526e-06L0 54Z"
        fill={colorMap[color]}
      />
    </svg>
  )
}

Logo.propTypes = {
  color: PropTypes.oneOf(['primary', 'inverse'])
}

Logo.defaultProps = {
  color: 'inverse'
}

export default Logo